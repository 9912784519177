$rate: 0.75;
.header {
  position: absolute;
  left: calc(50% - 50vw);
  right: calc(50% - 50vw);
  top: 0;
  display: flex;
  justify-content: center;

  & > div {
    max-width: 1440px;
  }
}
.termsBox {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 134px 0 0;
  margin: 0 20px;

  .terms {
    background: #202020;
    border-radius: 60px;
    padding: 60px;
    margin-bottom: 30px;
    h1 {
      font-size: 144px;
      line-height: 83%;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 42px;
    }
    div {
      font-family: 'Open Sans';
      //   font-weight: 600;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.5px;
      color: #ffffff;
      span {
        color: #fff96d;
        text-decoration: underline;
      }
      ul {
        margin: 0;
      }
    }
    @media (max-width: 1280px) {
      h1 {
        font-size: 100px;
        margin-bottom: 30px;
      }
      div {
        font-size: 18px;
      }
    }
    @media (max-height: 960px) and (min-width: 768px) {
      h1 {
        font-size: 80px;
        margin-bottom: calc(42px * $rate);
      }
      div {
        font-size: 16px;
      }
    }
    @media (max-width: 767px) {
      padding: 30px 20px;
      border-radius: 20px;
      h1 {
        font-size: 44px;
        margin-bottom: 30px;
      }
      div {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0 10px;
  }
}
