$rate: 0.75;
@import '../../variables.scss';

.headerBox {
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0px;
  position: absolute;
  width: 100%;
  max-width: 1600px !important;
  z-index: 9999;

  &.bgGradient {
    background: linear-gradient(180deg, #000000 13.21%, rgba(0, 0, 0, 0) 100%);
  }

  &.bgBlack::before {
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 84px;
    background: #121212;
  }

  @media (max-width: 767px) {
    background-color: transparent;

    .spaceRunnersLogo {
      height: 28px;
      width: 114px;
    }
  }

  @media (max-width: 860px) {
    // background-color: rgba(37, 37, 37, 0.5);
    position: relative;
    width: 100%;
    height: 68px;
    padding: 0 20px;
    .socialLink {
      display: none !important;
    }
    .headerBtns {
      display: none;
    }
    .walletBox1 {
      display: none !important;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    z-index: 10000;
    height: 65%;
    width: 65%;
    svg {
      margin-right: 12px;
    }
    @media (max-width: 767px) {
      svg {
        margin-right: 5px;
        &:first-child {
          height: 30px;
          width: 30px;
        }
        &:last-child {
          width: 192.5px;
          height: 13.8px;
        }
      }
    }
    @media (max-width: $mobileSize) {
      height: 100%;
      width: 100%;
    }

    @media (max-height: 960px) and (min-width: 768px) {
      //transform: scale($rate);
    }
  }

  .socialLink {
    display: flex;
    align-items: center;
    margin-right: 8px;

    a {
      margin-left: 12px;
      transition: transform 0.3s ease-in-out;
      display: flex;
      &:hover {
        transform: scale(1.2);
      }
    }
    // @media (max-height: 960px) and (min-width: 768px) {
    //   margin-right: calc(15px * $rate);
    //   a {
    //     margin-left: calc(24px * $rate);
    //   }
    // }
  }

  .headerBtns {
    display: flex;
    align-items: center;
    position: relative;
    .walletBox1 {
      display: flex;
      margin-left: 12px;
      align-items: center;
      border: 1px solid #ffffff;
      border-radius: 5px;
      padding: 0px 12px;
      height: 48px;
      cursor: pointer;
      z-index: 10000;
      font-size: 18px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: #ffffff;
        border: 1px solid #ffffff;
        color: #000000;
      }
    }
    .walletBox {
      display: flex;
      margin-left: 12px;
      align-items: center;
      //   background: #202020;
      //   border-radius: 12px;
      padding: 0px 16px;
      height: 52px;
      cursor: pointer;
      z-index: 10000;
      font-size: 18px;
      transition: transform 0.3s ease-in-out;
      &:hover {
        //background: #3b3b3b;
        color: #3b3b3b;
      }
      .mintedNum {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('./star.svg') center center no-repeat;
        color: #202020;
        margin-right: 8px;
        font-weight: 600;
        font-size: 15px;
        line-height: 100%;
        letter-spacing: -0.5px;

        span {
          padding-top: 5px;
          padding-right: 1px;
          display: flex;
        }
      }
      img {
        margin-right: 6px;
        width: 26px;
        height: 26px;
      }
      svg {
        width: 26px;
        height: 26px;
        display: none;
      }

      @media (max-width: 767px) {
        padding: 8px;
        margin-left: 9px;
        width: 40px;
        height: 40px;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
      }
      @media (max-width: 767px) {
        .mintedNum,
        img {
          margin-right: 0;
        }
        svg {
          display: block;
        }
        span {
          display: none;
        }
      }
    }

    .hamburger,
    .dropdown {
      display: none;
    }

    @media (max-width: 860px) {
      a.walletBox,
      .headerDropdown {
        display: none;
      }

      .hamburger,
      .dropdown {
        display: flex;
      }
      .hamburger {
        width: 52px;
        height: 52px;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 12px;
        margin-left: 12px;
        z-index: 1;

        span {
          height: 2px;
          display: flex;
          background: white;
          position: absolute;
          top: calc(50% - 1px);
          left: 16.5px;
          right: 16.5px;

          &:first-child {
            top: calc(50% - 8px);
          }
          &:last-child {
            top: calc(50% + 6px);
          }
        }

        @media (max-width: 767px) {
          width: 40px;
          height: 40px;
          span {
            left: 10px;
            right: 10px;
          }
        }
      }
      .dropdown {
        position: fixed;
        top: 67px;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 12px;
        background: #121212;
        padding: 50px 30px;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;

        .menuItem {
          border-bottom: 1px solid #414141;
          margin-bottom: 16px;
          width: 100%;
          padding-right: 30px;
          h3 {
            font-family: 'Bebas Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 100%;
            text-transform: uppercase;
            color: #ffffff;
            opacity: 0.5;
            margin-bottom: 16px;
          }
          div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            a {
              margin-bottom: 16px;
              display: flex;
              align-items: center;
              width: max-content;
              svg {
                margin-right: 6px;
              }
              span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.5px;
                color: #ffffff;
                margin-left: 10px;
                opacity: 0.5;
              }
            }
          }
        }
        .yellow {
          color: #fff96d;
        }
        .connect {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }
          span {
            font-family: 'Bebas Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 100%;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  //   @media (max-height: 960px) and (min-width: 1121px) {
  //     padding: 0 calc($rate * 54px) calc($rate * 34px);
  //   }
  //   @media (max-height: 960px) and (min-width: 768px) {
  //     padding: 0 calc($rate * 34px) calc($rate * 34px);
  //   }
}

.backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
.dropdown {
  position: relative;
  z-index: 10000;

  .dropdownBox {
    position: absolute;
    top: calc(100% + 20px);
    left: calc(50% - 228px);
    height: 500px;
    overflow: auto;
    width: 456px;
    background: #202020;
    border-radius: 42px;
    padding: 30px;
    z-index: 9999;

    p {
      text-align: center;
      font-family: 'Bebas Neue';
      font-weight: normal;
      font-size: 40px;
      line-height: 40px;
      margin: 30px 0 50px;
    }
    .nfts {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .nft {
        width: 120px;
        height: 120px;
        margin: 5px;
        margin-bottom: 25px;
        border-radius: 25px;
      }
    }
    .nftBtn {
      display: flex;
      justify-content: center;
      a {
        padding: 22px 45px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 24px;
        font-family: 'Bebas Neue';
        font-weight: normal;
        font-size: 30px;
        line-height: 30px;
      }
    }

    @media (max-width: 767px) {
      position: fixed;
      left: 10px;
      right: 10px;
      top: 100px;
      width: auto;
    }
  }
}

.headerDropdown {
  margin-left: 30px;
  display: flex;
  align-items: center;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #ffffff;
  width: -webkit-fill-available;
  cursor: pointer;
  position: relative;
  z-index: 999;
  & > svg {
    margin-left: 3px;
  }
}
.headerDropContent {
  position: absolute;
  top: calc(100% + 32px);
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20.5px 0;
    position: relative;

    &::before {
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      background: #121212;
      top: 84px;
      height: 173px;
      border-top: 1px solid #414141;
      z-index: -1;
    }
    &.experiences::before {
      height: 237px;
    }
    // &::after {
    //   content: '';
    //   position: fixed;
    //   top: 0%;
    //   height: 84px;
    //   left: 0;
    //   right: 0;
    //   z-index: -1;
    //   background: #121212;
    //   border-bottom: 1px solid #414141;
    // }

    a {
      margin: 7.5px 0;
      display: flex;
      align-items: center;
      width: max-content;
      opacity: 0.9;
      letter-spacing: 3px;
      font-size: 16px;
      svg {
        margin-right: 6px;
      }
      font-weight: 400;
      span {
        // font-family: 'Open Sans';
        // font-style: normal;
        // font-size: 12px;
        // line-height: 140%;
        // letter-spacing: -0.5px;
        color: #ffffff;
        margin-left: 5px;
      }
      transition: all ease 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.headerbackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
