$rate: 0.75;
.footerBox {
  min-height: 134px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 34px 10px;
  position: absolute;
  width: 100%;
  //   background: linear-gradient(180deg, #000000 13.21%, rgba(0, 0, 0, 0) 100%);

  @media (max-height: 960px) and (min-width: 768px) {
    padding: 0 calc($rate * 34px) 0;
  }

  .logo {
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
    }
  }

  .termsLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      a {
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
      }
      @media (max-width: 424px) {
        display: flex;
        flex-direction: column;
        a {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
    .socialLink {
      display: flex;
      align-items: center;

      a {
        margin-left: 24px;
        display: flex;
        align-items: center;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
        &.disabled {
          transition: none;
          &:hover {
            transform: none;
          }
        }
      }
    }

    .rightContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 25vh;
      p {
        a {
          font-family: 'Bebas Neue';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 100%;
          text-transform: uppercase;
          color: #ffffff;
          opacity: 0.5;
        }
        @media (max-width: 424px) {
          display: flex;
          flex-direction: column;
          a {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }

  //   @media (max-width: 1030px) {
  //     flex-direction: column;
  //     align-items: flex-start;
  //     .termsLinks {
  //       margin-left: 0;
  //       margin-top: 25px;
  //     }
  //   }

  @media (max-width: 767px) {
    align-items: center;
    // padding: 40px;
    flex-direction: column;
    .termsLinks {
      flex-direction: column;
      flex-wrap: wrap;
      .socialLink {
        margin-top: 20px;
        margin-bottom: 30px;
        a {
          margin: 0 12px;
        }
      }
    }
  }
}
