@import '../../variables.scss';

.drops {
  padding-left: $sideMarginWeb;
  padding-right: $sideMarginWeb;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 175px;

  .title {
    color: #ffffff;
    font-family: 'Bebas Neue';
    font-size: 63px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 45px;
    text-transform: uppercase;
    text-align: center;
  }

  .subtitle {
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 148.4%;
    margin: 0 auto;
    margin-bottom: 95px;
    text-align: center;
    max-width: 757px;
  }

  .items {
    display: flex;
    justify-content: center;
    padding-bottom: 1px;
  }

  .item {
    flex: 1;
    text-align: center;
  }

  .itemTitle {
    color: #c0dedb;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: italic;
    font-weight: 600;
    line-height: 148.4%;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  .itemName {
    font-family: Bebas Neue;
    font-size: 50.769px;
    font-style: normal;
    font-weight: 700;
    line-height: 90.5%;
    margin-bottom: 15px;
    margin-top: 22px;
    text-align: center;
    text-transform: uppercase;
  }

  .itemDescription {
    text-align: center;
  }

  .video {
    object-fit: cover;
  }

  @media only screen and (min-width: $tabletSize) and (max-width: $maxWidth) {
    .dropItems {
      grid-template-columns: repeat(4, 22vw);
      grid-gap: 8px;
      width: 93vw;
      justify-content: space-between;
    }
  }
  @media only screen and (max-width: $tabletSize) {
    padding: 0 20px;
    margin-bottom: 120px;

    .subtitle {
      margin-bottom: 45px;
    }

    .items {
      display: block;
    }

    .item {
      margin-top: 45px;
      padding: 0 20px;
    }

    img {
      width: 300px;
    }

    .video {
      width: 300px;
    }

    .dropItems {
      overflow-x: auto;
      overflow-y: hidden;
      margin-top: 3px;
      padding-bottom: 16px;
      margin-bottom: 40px;
      justify-content: flex-start;
      & > a {
        width: 300px;
      }
      grid-gap: 12px;
      align-self: center;
    }
  }
}
