@import '../../../variables.scss';
.leadershipItem {
  padding: 40px 18px;

  img {
    border-radius: 5px;
    width: 119px;
    // height: 186px;
  }
  .imgWrapper {
    position: relative;
    overflow: hidden;
    img {
      transition: all ease 0.3s;
    }

    &:hover img {
      transform: scale(1.2);
    }
    .desc {
      position: absolute;
      width: 170px;
      height: 170px;
      border-radius: 5px;
      padding: 14px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      transition: all 300ms ease-in-out;
      .txt {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        /* or 20px */

        text-align: center;
        letter-spacing: -0.5px;

        color: #ffffff;
        margin-bottom: 8px;
      }
      svg {
        bottom: 2px;
        position: relative;
        width: 12px;
      }
    }
  }
  .role {
    color: var(--more, #adadad);
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.8px;
    text-align: center;
  }
  .name {
    color: var(--text, #e4e4e4);
    text-align: center;
    text-transform: uppercase;
    font-family: 'Bebas Neue';
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
    letter-spacing: -0.8px;
  }

  .media {
    align-items: center;
    background: rgba(37, 37, 37, 0.37);
    backdrop-filter: blur(18.2px);
    border-radius: 50%;
    display: flex;
    padding: 0 6px;
    height: 24px;
    width: 24px;
  }

  @media (max-width: $mobileSize) {
    padding: 18px;
    margin: 8px;

    img {
      width: 132px;
      height: 132px;
    }
    .role {
      font-size: 11px;
    }
    .name {
      font-size: 14px;
    }
    .imgWrapper {
      .desc {
        width: 120px;
        height: 120px;
        padding: 2px;
        .txt {
          font-size: 11px;
          margin-bottom: 3px;
        }
      }
    }
  }
}
