@import '../../../variables.scss';

.ourNewsItem {
  padding: 24px;
  background: #1b1b1b;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
  transition: all 200ms;
  cursor: pointer;
  max-width: 990px;

  img {
    opacity: 0.5;
  }
  &:hover {
    background: #121212;
    box-shadow: 0px 0px 5px rgb(255 255 255);

    img {
      opacity: 1;
    }
  }
  .txt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 72%;

    .title {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 16px;
    }

    .desc {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 400;
      font-size: 16px;
      opacity: 0.5;
      width: 100%;
    }
  }
  @media (max-width: $tabletSize) {
    flex-direction: column;
    .txt {
      width: 100%;
      .title {
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 10px;
      }
      .desc {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 16px;
        text-align: left;
        white-space: unset;
      }
    }
  }
}
