$rate: 0.5;
@import '../../variables.scss';

.header {
  position: absolute;
  left: calc(50% - 50vw);
  right: calc(50% - 50vw);
  top: 0;
  display: flex;
  justify-content: center;

  & > div {
    max-width: 1440px;
  }
}
.bannerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  overflow-x: hidden;
  margin-bottom: 0;
  //   background-image: linear-gradient(
  //       180deg,
  //       rgba(0, 0, 0, 0) -8.42%,
  //       rgba(0, 0, 0, 0.34112) 40.87%,
  //       #121212 97.81%
  //     ),
  //     url(bannerimage.jpg);
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  //   height: 730px;
  // padding-bottom: 42px;
  @media (max-width: $mobileSize) {
    background-size: cover;
    background-position: 49%;
    height: 850px;
    margin-bottom: 0;

    .button2 {
      display: none !important;
    }
  }

  .bannerImg {
    // div {
    //   width: 1000px;
    //   background-size: 100%;
    //   img {
    //     width: 100%;
    //   }
    // }
    // -webkit-animation: mainAppear 0.8s;
    // animation: mainAppear 0.8s;
    // animation-timing-function: ease-in;
    // margin-bottom: -140px;
    @media (max-height: 960px) and (min-width: 768px) {
      div {
        width: calc(1000px * $rate);
      }
      margin-bottom: calc(-140px * $rate);
    }
  }

  .button1 {
    background: #fff96d;
    height: 48px;
    width: 155px;
    border-radius: 5px;
    // margin-right: 8px;
    display: flex;
    justify-content: center;
    margin-top: 12px;

    cursor: pointer;
    transition: all ease 0.3s;
    span {
      color: #121212;
      letter-spacing: 0.3px;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 48px;
      display: flex;
      align-items: center;
      text-transform: uppercase;

      @media (max-height: $mobileSize) {
        color: #121212;
        font-style: normal;
        display: flex;
        align-items: center;
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height */

        text-transform: uppercase;

        color: #121212;
      }
    }
    @media (max-height: 960px) and (min-width: 768px) {
      //background-size: calc(176px * $rate) calc(48px * $rate);
      // height: calc(55px * $rate);
      span {
        font-size: 18px;
        line-height: normal;
      }
    }
    &:hover {
      background: #f1ea33;
      transform: scale(1.02);
    }
  }

  .button2 {
    border: 1px solid #ffffff;
    border-radius: 5px;
    height: 48px;
    width: 131px;
    display: flex;
    justify-content: center;

    p {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 0.3px;
      line-height: 48px;
      display: flex;
      align-items: center;
    }
    @media (max-height: 960px) and (min-width: 768px) {
      // background-size: calc(131px * $rate) calc(48px * $rate);
      //height: calc(55px * $rate);
      p {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  h1 {
    //z-index: -1;
    font-size: 120px;
    line-height: 100%;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    // margin-bottom: 5px;
    max-width: 945px;
    padding-top: 535px;
    letter-spacing: 1px;

    @media (max-width: $mobileSize) {
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
      line-height: 90%;
      /* or 65px */

      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
      padding-top: 435px;
      letter-spacing: 0px;
    }
  }

  p {
    height: 22px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */

    text-align: center;
    letter-spacing: -0.5px;

    color: #ffffff;
  }

  @media (max-height: 960px) and (min-width: 768px) {
    margin-bottom: calc(120px * $rate);
    h1 {
      font-size: 105px;
      line-height: 90%;
      //margin-bottom: calc(24px * $rate);
      //padding-top: calc(670px * $rate);
    }
    p {
      font-size: 16px;
      max-width: calc(755px * $rate);
    }
  }

  @media (max-width: 767px) {
    padding: 0 10px;
    h1 {
    }
    p {
      height: 18px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      /* or 18px */

      text-align: center;
      letter-spacing: -0.5px;

      color: #ffffff;
    }
    .buttonbox {
      width: 135px;
      height: 42px;
    }
    .button1 {
      width: auto;
      height: auto;
      margin-top: 17px;

      span {
        color: #121212;
        font-style: normal;
        display: flex;
        align-items: center;
        font-family: 'Bebas Neue';
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height */

        text-transform: uppercase;
        margin: 14px 20px 12px;
        letter-spacing: -0.4px;
      }
    }
    .bannerImg {
      margin-bottom: -65px;
      div {
        width: 624px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          height: 80px;
          top: -5px;
          left: 0;
          right: 0;
          background: linear-gradient(
            180deg,
            #000000 13.21%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }
    }
  }
}

.socialLink {
  display: none;
  align-items: center;
  margin-top: 24px;

  a {
    margin: 0 12px;
  }

  @media (max-width: 1379px) {
    display: flex;
  }
}

@-webkit-keyframes mainAppear {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  60% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes mainAppear {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  60% {
    opacity: 1;
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes mainBg {
  0% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 68, 197, 0.3) 0%,
      rgba(89, 68, 197, 0) 100%
    );
  }
  10% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 68, 197, 0.3) 0%,
      rgba(89, 68, 197, 0) 100%
    );
  }
  20% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(207, 43, 38, 0.3) 0%,
      rgba(207, 43, 38, 0) 100%
    );
  }
  30% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(207, 43, 38, 0.3) 0%,
      rgba(207, 43, 38, 0) 100%
    );
  }
  40% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(143, 226, 192, 0.3) 0%,
      rgba(143, 226, 192, 0) 100%
    );
  }
  50% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(143, 226, 192, 0.3) 0%,
      rgba(143, 226, 192, 0) 100%
    );
  }
  60% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(206, 167, 133, 0.3) 0%,
      rgba(206, 167, 133, 0) 100%
    );
  }
  70% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(206, 167, 133, 0.3) 0%,
      rgba(206, 167, 133, 0) 100%
    );
  }
  80% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(229, 223, 71, 0.3) 0%,
      rgba(229, 223, 71, 0) 100%
    );
  }
  90% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(229, 223, 71, 0.3) 0%,
      rgba(229, 223, 71, 0) 100%
    );
  }
  100% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 68, 197, 0.3) 0%,
      rgba(89, 68, 197, 0) 100%
    );
  }
}

@keyframes mainBg {
  0% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 68, 197, 0.3) 0%,
      rgba(89, 68, 197, 0) 100%
    );
  }
  10% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 68, 197, 0.3) 0%,
      rgba(89, 68, 197, 0) 100%
    );
  }
  20% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(207, 43, 38, 0.3) 0%,
      rgba(207, 43, 38, 0) 100%
    );
  }
  30% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(207, 43, 38, 0.3) 0%,
      rgba(207, 43, 38, 0) 100%
    );
  }
  40% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(143, 226, 192, 0.3) 0%,
      rgba(143, 226, 192, 0) 100%
    );
  }
  50% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(143, 226, 192, 0.3) 0%,
      rgba(143, 226, 192, 0) 100%
    );
  }
  60% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(206, 167, 133, 0.3) 0%,
      rgba(206, 167, 133, 0) 100%
    );
  }
  70% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(206, 167, 133, 0.3) 0%,
      rgba(206, 167, 133, 0) 100%
    );
  }
  80% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(229, 223, 71, 0.3) 0%,
      rgba(229, 223, 71, 0) 100%
    );
  }
  90% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(229, 223, 71, 0.3) 0%,
      rgba(229, 223, 71, 0) 100%
    );
  }
  100% {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(89, 68, 197, 0.3) 0%,
      rgba(89, 68, 197, 0) 100%
    );
  }
}
