@import '../../variables.scss';

.investorAdvisors {
  .title {
    font-family: 'Bebas Neue';
    font-weight: 400;
    letter-spacing: 4px;
    text-align: center;
    margin-bottom: 80px;
    font-size: 16px;
    padding-top: 80px;
  }
  .investors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 30px;
    max-width: 1200px;
    margin: 0 auto 60px;
  }
  .advisors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 30px;
    max-width: 1260px;
    margin: 0 auto;
  }
  @media (max-width: $mobileSize) {
    .title {
      padding-top: 40px;
    }
  }
  @media (max-width: 1200px) {
    .investors {
      padding: 0px 28px;
    }
    .advisors {
      padding: 0px 19px;
    }
  }
}
