* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212;
  color: white;
  overflow-x: hidden;
}

.lock-scroll {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 400;
  src: url('./styles/font/BebasNeue-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('./styles/font/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  src: url('./styles/font/ProximaNova-Regular.ttf');
}

@font-face {
  font-family: 'Digital Numbers';
  font-weight: 400;
  src: url('./styles/font/DigitalNumbers-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('./styles/font/Poppins-Regular.ttf');
}

p {
  font-family: 'Open Sans';
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h3 {
  font-weight: 400;
}

body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body::-webkit-scrollbar-button {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: #fff;
  border: 0px solid transparent;
  border-radius: 2.5px;
  filter: none;
  transition: background-color 0.25s, filter 0.25s;
}
::-webkit-scrollbar-thumb:active {
  filter: brightness(80%);
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none transparent;
  border-radius: 2.5px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.ps--active-y > .ps__rail-y {
  opacity: 1 !important;
  background-color: #1b1b1b !important;
  width: 4px !important;
}
.ps__thumb-y {
  background-color: #ffffff !important;
  border-radius: 100px !important;
  width: 4px !important;
  right: 0px !important;
}
.ps--active-x > .ps__rail-x {
  background-color: rgba(255, 255, 255, 0.052) !important;
  opacity: 1 !important;
  height: 4px !important;
  border-radius: 100px !important;
}
.ps__thumb-x {
  background-color: #ffffff !important;
  border-radius: 100px !important;
  height: 4px !important;
  right: 0px !important;
  bottom: 0px !important;
  width: 300px !important;
}
