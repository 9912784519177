@import '../../variables.scss';

.featuredBox {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  .title {
    font-weight: normal;
    letter-spacing: 4px;
    text-align: left;
    padding-left: $sideMarginWeb !important;
    margin-bottom: 28px;
  }
  .featuredLinks {
    position: relative;
    .carouselItem {
      display: flex;
      justify-content: center;
    }
  }
  .linkItem {
    display: flex;
    align-items: center;
    padding: 0 10px;
    img {
      //   height: 31px;
      //   max-width: 165px;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  .right {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: black;
    background: linear-gradient(
      270deg,
      rgb(0 0 0) 0%,
      rgb(0 0 0) 13%,
      rgba(0, 0, 0, 0) 80%,
      rgba(1, 210, 252, 0) 100%
    );
    width: 160px;
  }
  .left {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(
      90deg,
      rgb(0 0 0) 0%,
      rgb(0 0 0) 13%,
      rgba(0, 0, 0, 0) 80%,
      rgba(1, 210, 252, 0) 100%
    );
    width: 160px;
  }
  @media (max-width: 760px) {
    margin-top: 0px;
  }
  @media (max-width: $tabletSize) {
    .title {
      padding-left: $sideMarginMobile !important;
    }
  }
  @media (max-width: $mobileSize) {
    .linkItem {
      img {
        height: 30px;
        max-width: 110px;
      }
    }
  }
}
