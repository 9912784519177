@import '../variables.scss';
@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'react-multi-carousel/lib/styles.css';

.Home {
  margin: 0 auto;
  position: relative;
  //  min-height: 200vh;
  // height: 100vh;
  overflow: hidden;
  // background: #121212;
  z-index: 1;
  padding-bottom: 120px;

  @media (max-width: 767px) {
    padding-bottom: 170px;
  }

  .Home-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -10;

    .poster {
      display: block;
      background-repeat: no-repeat;
      margin: auto;
      background-image: url(../DesertJump_BG.png);
      background-size: cover;
      height: 100vh;
      width: 100vw;
      background-position: top center;

      @media (max-width: $mobileSize) {
        /*  position: absolute;
        width: 100%;
        height: 200vh;
        top: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        background-image: url(../bannerimagemobile.png);
        background-size: 100%; */
      }
    }
  }

  .overlay {
    content: ' ';
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    z-index: -1;

    @media (max-width: $mobileSize) {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .spacer {
    height: 100vh;
    position: relative;
    width: 100%;

    @media (min-width: 767px) {
      height: 200vh;
    }
  }

  .home-lottie-animation {
    overflow: visible;

    @media (max-width: 768px) {
      // height: 105vh !important;
    }
  }

  .followItem {
    //  background-color: #ffffff;
    bottom: 70vh;
    height: 50vh;
    position: absolute;
    width: 100px;

    @media (min-width: 767px) {
      bottom: 70vh;
    }
  }

  //   text-align: center;
  //   margin: 0 auto;
  //   position: relative;
  //   //   padding-bottom: 215px;
  //   background: #121212;
  //   min-height: 100vh;
  //   &.bg-home {
  //     background: url(bannerimage.jpg) top center no-repeat;
  //     background-size: cover;
  //     background-attachment: fixed;
  //     // position: fixed;
  //     // top: 0;
  //     // right: 0;
  //     // bottom: 0;
  //     // left: 0;
  //     // overflow: hidden;
  //     // z-index: -10;

  //     @media screen and (max-width: 768px) {
  //       background-size: auto 100vh;
  //     }
  //     &.overlay {
  //       background: linear-gradient(
  //         180deg,
  //         rgba(0, 0, 0, 0) -8.42%,
  //         rgba(0, 0, 0, 0.1) 64.87%,
  //         rgba(18, 18, 18, 0.8) 97.81%
  //       );
  //       background-size: auto 100vh;
  //       background-attachment: fixed;
  //     }
  //   }
}
.container {
  max-width: $maxWidth;
  margin: auto;
}
@media (max-height: 960px) and (min-width: 768px) {
  .Home {
    /* // max-width: calc($maxWidth * 0.75); */
  }
}

.title-block {
  position: fixed;
  top: 47px;
  padding-top: 137px;
  text-align: center;
  width: 100%;

  img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }

  @media (max-width: 767px) {
    padding-top: 60px;

    #imageHome1 {
      width: 260px;
    }

    #imageHome2 {
      margin-top: 7px;
      padding-left: 0;
      width: 260px;
    }

    #imageHome3 {
      display: none;
    }

    #imageHome4 {
      bottom: 12px;
      position: relative;
      width: 239px;
    }
  }

  .image-2 {
    padding-left: 26px;
  }
}

.frostyGlass {
  background: rgba(37, 37, 37, 0.9);
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
}
.textBody {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: #ffffff;
  text-align: left;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .frostyGlass {
    background: rgba(37, 37, 37, 0.37);
    backdrop-filter: blur(18.2px);
    -webkit-backdrop-filter: blur(18.2px);
  }
}
