@import './variables.scss';
@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'react-multi-carousel/lib/styles.css';

.App {
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  background: #121212;
  z-index: 1;
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding-bottom: 170px;
  }
  .App-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -10;

    .poster {
      display: block;
      background-repeat: no-repeat;
      margin: auto;
      background-image: url(bannerimage.jpg);
      background-size: cover;
      height: 100vh;
      width: 100vw;
      @media (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        background-size: cover;
        background-position: top center;
      }
    }
    .overlay {
      position: absolute;
      display: block;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
      z-index: 2;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) -8.42%,
        rgba(0, 0, 0, 0.1) 64.87%,
        rgba(18, 18, 18, 0.8) 97.81%
      );
      background-size: auto 100vh;
    }
  }

  //   text-align: center;
  //   margin: 0 auto;
  //   position: relative;
  //   //   padding-bottom: 215px;
  //   background: #121212;
  //   min-height: 100vh;
  //   &.bg-home {
  //     background: url(bannerimage.jpg) top center no-repeat;
  //     background-size: cover;
  //     background-attachment: fixed;
  //     // position: fixed;
  //     // top: 0;
  //     // right: 0;
  //     // bottom: 0;
  //     // left: 0;
  //     // overflow: hidden;
  //     // z-index: -10;

  //     @media screen and (max-width: 768px) {
  //       background-size: auto 100vh;
  //     }
  //     &.overlay {
  //       background: linear-gradient(
  //         180deg,
  //         rgba(0, 0, 0, 0) -8.42%,
  //         rgba(0, 0, 0, 0.1) 64.87%,
  //         rgba(18, 18, 18, 0.8) 97.81%
  //       );
  //       background-size: auto 100vh;
  //       background-attachment: fixed;
  //     }
  //   }
}
.container {
  max-width: $maxWidth;
  margin: auto;
}
@media (max-height: 960px) and (min-width: 768px) {
  .App {
    /* // max-width: calc($maxWidth * 0.75); */
  }
}
.frostyGlass {
  background: rgba(37, 37, 37, 0.9);
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
}
.textBody {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.5px;
  color: #ffffff;
  text-align: left;
}

.grecaptcha-badge {
  visibility: hidden;
  bottom: 84px !important;
  z-index: 1000;

  &.show {
    visibility: visible;
  }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .frostyGlass {
    background: rgba(37, 37, 37, 0.37);
    backdrop-filter: blur(18.2px);
    -webkit-backdrop-filter: blur(18.2px);
  }
}

.bg-home {
}
.bg-home-gradient {
}
