@import '../../../variables.scss';
.dropItemWrapper {
  background: #1b1b1b;
  border-radius: 5px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  transition: all 200ms;
  box-shadow: 0px 0px 5px transparent;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 5px rgb(255 255 255);
  }

  .titleContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      font-weight: 400;
      font-size: 22px;
      line-height: 24px;
      text-align: left;
    }
    .img {
      height: 22px;
      width: 22px;
    }
  }
  .image {
    position: relative;
    overflow: hidden;
  }
  //   .enLargen {
  //     height: 300px;
  //     width: 300px;
  //   }
  img {
    border-radius: 5px;
    transition: all 200ms;
  }

  .desc {
    position: absolute;
    width: 271px;
    height: 112px;
    display: flex;
    align-items: center;
    padding: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;
    transition: all 300ms ease-in-out;
  }
  @media only screen and (min-width: $tabletSize) and (max-width: $maxWidth) {
    // height: 22vw;
    padding: 12px;
    padding-bottom: 0px;

    .title {
      font-size: 16px;
      //   margin-bottom: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .footer {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 15px 0 20px;
      font-size: 13px;
    }
    // img {
    //   height: 100%;
    //   width: 100%;
    // }
  }
  @media only screen and (min-width: $mobileSize) and (max-width: $tabletSize) {
    padding: 12px;
    padding-bottom: 0px;

    .title {
      font-size: 16px;
      //   margin-bottom: 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .footer {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 3px;
      font-size: 13px;
      padding-bottom: 12px;
    }
    img {
      height: 100%;
      width: 100%;
    }
  }
  @media (max-width: $mobileSize) {
    img {
      width: 100%;
      height: 98%;
    }
  }
}
