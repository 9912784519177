@import '../../../variables.scss';
.advisorsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96px;
  margin: 0 25px 50px;
  .img {
    height: 80px;
    width: 80px;
    position: relative;
    overflow: hidden;
    margin-bottom: 12px;
    border-radius: 100%;
    .link {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      transition: all 300ms ease-in-out;
    }
  }
  .name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* or 20px */

    text-align: center;
    letter-spacing: -0.5px;

    color: #ffffff;
  }
  .lastName {
    margin-bottom: 8px;
  }
  .company {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 140%;
    /* or 14px */

    text-align: center;
    letter-spacing: -0.5px;
    text-transform: uppercase;

    color: #ffffff;
  }
  .role {
    opacity: 0.5;
  }
  @media (max-width: $tabletSize) {
    margin-bottom: 50px;
    margin-right: 8px;
    margin-left: 8px;
  }
}
