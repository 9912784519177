@import '../../variables.scss';

.ourNews {
  display: grid;
  grid-template-columns: 1fr 3fr;
  justify-content: space-between;
  padding-top: 80px;
  padding-left: $sideMarginWeb;
  padding-right: $sideMarginWeb;
  max-width: 1440px;

  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 4px;
    text-align: left;
    flex-shrink: 0;
    width: max-content;
    margin-right: 30px;
  }

  .items {
    display: flex;
    flex-direction: column;
    height: 320px;
    margin-right: 28px;
    padding: 1px;
  }
  .wrapper {
    position: relative;
    max-width: 1020px;
    width: 100%;
    .overLay {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      top: 83%;

      background: linear-gradient(0deg, black, #0000ff00 100%) no-repeat;
    }
  }
  .loadMore {
    height: 48px;
    border: 1px solid #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 40px;
    margin-top: -11px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
  }
  @media only screen and (min-width: $tabletSize) and (max-width: 1012px) {
    grid-template-columns: 1fr;
    grid-gap: 28px;
    .wrapper {
      width: 93vw;
    }
  }
  @media (max-width: $tabletSize) {
    padding-left: $sideMarginMobile;
    padding-right: $sideMarginMobile;
    grid-template-columns: 1fr;
    grid-gap: 28px;
    .wrapper {
      width: 95vw;
    }
    .items {
      height: auto;
    }
  }
}
