$rate: 0.75;
@import '../../variables.scss';

.communityPage {
  padding: 0 30px 0px;
  display: flex;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
}
.communityBox {
  color: white;
  display: flex;
  width: 100%;
  padding-left: 40px;
  flex-direction: row;
  background: #1b1b1b;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  margin: 85px auto;
  max-width: 1140px;

  .imgBox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $mobileSize) {
      padding-bottom: 40px;
    }
  }
  .img1 {
    margin-right: -240px;
    margin-left: -240px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    .img1 {
      margin-left: 0;
      margin-right: -40%;
      width: 80%;
    }
  }

  @media (max-width: $mobileSize) {
    margin: 0 $sideMarginMobile;
    flex-direction: column;
    margin-top: 40px;
  }

  .socialLink {
    display: flex;
    align-items: center;
    margin-right: 8px;

    a {
      margin-right: 15px;
      transition: transform 0.3s ease-in-out;
      display: flex;
      &:hover {
        transform: scale(1.2);
      }
      &.disabled {
        margin-right: 15px;
        display: flex;
        transition: none;

        &:hover {
          transform: none;
        }
      }
    }
    @media (max-height: 960px) and (min-width: 992px) {
      // margin-bottom: calc($rate * (-40px));

      & > svg {
        margin-top: calc($rate * (-400px));
      }

      h1 {
        font-size: calc($rate * 144px);
        line-height: calc($rate * 120px);
      }
    }
    @media (max-width: $mobileSize) {
      padding-left: 16px;
    }
  }

  .sneakerImg {
    width: 398px;
    //   height: 398px;

    @media (max-width: 767px) {
      width: 60%;
    }

    @media (max-height: 960px) and (min-width: 768px) {
      //   margin-bottom: calc((-100px) * $rate);
      //   max-width: calc($rate * 673px);
    }
  }

  .buttonbox {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .button1 {
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: #ffffff;
    border-radius: 5px;
    color: #000000;
    padding: 16px 24px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: #121212;
    margin-right: 16px;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    z-index: 1;

    @media (max-width: $mobileSize) {
      padding-left: 20px;
    }

    p {
      width: 400px;
      padding-bottom: 20px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.5px;
      color: #ffffff;
    }

    h1 {
      font-size: 64px;
      line-height: 76px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 27px;
      text-align: left;
      max-width: 233px;
      margin-right: -200px;
      font-family: 'Bebas Neue';
      font-weight: normal;
      max-width: 233px;
      @media (max-width: $mobileSize) {
        font-size: 60px;
        line-height: 60px;
      }
    }

    h4 {
      font-family: 'Bebas Neue';
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase;
      color: #fff96d;
      margin-bottom: 25px;
      text-align: left;
      font-style: normal;

      @media (max-width: 1439px) {
        margin-bottom: 26px;
      }
      @media (max-width: 767px) {
        margin-bottom: 23px;
      }
      @media (max-width: $mobileSize) {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  & > svg {
    width: 100%;
    max-width: 1400px;
    margin-top: -285px;
    z-index: -1;
  }

  h1 {
    font-size: 144px;
    line-height: 120px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 30px;
  }

  p {
    max-width: 675px;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.5px;
    margin-bottom: 45px;
    span {
      font-weight: 700;
      color: #9c40e5;
      &.cyan {
        color: #b0e7ff;
      }
    }
  }
  h3 {
    font-size: 490px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #202020;
  }
  .btns {
    display: flex;
    & > a {
      margin: 0 7px;
    }
    @media (max-width: 767px), (min-height: 961px) and (max-width: 991px) {
      flex-direction: column;
      & > a {
        margin: 7px;
      }
    }
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    font-weight: normal;
    font-family: 'Bebas Neue';
    font-size: 18px;
    color: #121212;
    line-height: 100%;
    text-transform: uppercase;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.08);
    }

    @media (max-width: $mobileSize) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  @media (max-width: 991px) {
    position: relative;
    padding: 0 30px;
    h1 {
      font-size: 120px;
      line-height: 100px;
    }
    & > svg {
      position: absolute;
      margin-top: 0;
      top: -250px;
    }
    margin-bottom: 125px;
  }
  @media (max-width: 767px) {
    h1 {
      font-size: 75px;
      line-height: 78%;
      max-width: 355px;
    }
    margin-bottom: 83px;
  }
  @media (max-height: 960px) and (min-width: 768px) {
    // padding-left: 40px;
    margin: calc($rate * 85px) auto;
    h1 {
      font-size: calc($rate * 120px);
      line-height: calc($rate * 100px);
      margin-bottom: calc($rate * 30px);
    }
    & > svg {
      top: calc($rate * (-200px));
      margin-top: 0;
      max-width: calc($rate * 1400px);
      height: calc(490px * $rate);
    }
    p {
      max-width: calc($rate * 710px);
      font-size: 16px;
      margin-bottom: calc($rate * 45px);
    }
    h3 {
      font-size: calc($rate * 490px);
    }
    a {
      //   height: calc($rate * 78px);
      //   border-radius: calc($rate * 24px);
      font-size: 16px;
    }
  }
  @media (max-height: 960px) and (min-width: 992px) {
    // padding: 15px calc($rate * 60px);
    // margin-bottom: calc($rate * (-40px));

    & > svg {
      margin-top: calc($rate * (-400px));
    }

    h1 {
      font-size: calc($rate * 144px);
      line-height: calc($rate * 120px);
    }
  }
  @media (max-width: 767px), (min-height: 961px) and (max-width: 991px) {
    & > svg {
      top: calc(40%);
      transform: translateY(-50%);
    }
  }
}
