@import '../../variables.scss';

.leaderShip {
  .title {
    font-weight: 400;
    letter-spacing: 4px;
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;
    padding-top: 40px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;

    @media (max-width: 786px) {
      max-width: 615px;
    }

    @media (min-width: 786px) and (max-width: $maxWidth) {
      max-width: 786px;
    }
  }
  .imgWrapper {
    position: relative;
    overflow: hidden;
  }
}
