$rate: 0.75;
.header {
  position: absolute;
  left: calc(50% - 50vw);
  right: calc(50% - 50vw);
  top: 0;
  display: flex;
  justify-content: center;

  & > div {
    max-width: 1440px;
  }
}

.content {
  padding-top: 180px;
  h1 {
    font-size: 96px;
    padding: 0 20px;
  }

  .groupList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 100px;

    & > div {
      width: calc(100% / 3);
      padding: 30px 20px;

      @media (max-width: 1023px) {
        width: 50%;
      }
      @media (max-width: 679px) {
        width: 100%;
      }
    }
  }
  @media (max-height: 960px) and (min-width: 768px) {
    padding-top: calc($rate * 180px);
    h1 {
      font-size: calc($rate * 96px);
      padding: 0 calc($rate * 20px);
    }

    .groupList {
      margin-bottom: calc($rate * 100px);

      & > div {
        width: calc(100% / 3);
        padding: calc($rate * 30px) calc($rate * 20px);
      }
    }
  }
}

.burnGroupBox {
  padding: 40px 30px;
  border-radius: 16px;
  background: #202020;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 40px;
    margin-bottom: 10px;
  }
  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    margin-top: 10px;
    strong {
      font-size: 24px;
    }
  }
  @media (max-height: 960px) and (min-width: 768px) {
    padding: calc($rate * 40px) calc($rate * 30px);
    // border-radius: calc($rate * 16px);
    h3 {
      font-size: calc($rate * 40px);
    }
    p {
      font-size: 16px;
      strong {
        font-size: 18px;
      }
    }
  }
}

.burnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  .burnTitle {
    display: flex;
    align-items: center;
    font-size: 40px;
    span {
      font-weight: 100;
      // font-family: 'Open Sans';
      font-size: 36px;
      margin-left: 20px;
    }
    @media (max-width: 767px) {
      font-size: 40px;
      span {
        font-size: 38px;
      }
    }
  }
  a {
    font-family: 'Open Sans';
    font-size: 24px;
    color: #b0e7ff;
    font-style: italic;
    margin-top: 10px;
  }
  .burnBoxList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    margin-bottom: 40px;
    padding-bottom: 40px;
    max-width: 1200px;
    & > div {
      width: 50%;
      padding: 30px 20px 10px;

      @media (max-width: 1023px) {
        width: 100%;
        max-width: 540px;
      }
    }
  }
  @media (max-height: 960px) and (min-width: 768px) {
    .burnTitle {
      font-size: calc($rate * 40px);
      span {
        font-size: calc($rate * 36px);
      }
    }
    a {
      font-size: calc($rate * 24px);
    }
    .burnBoxList {
      margin-bottom: calc($rate * 40px);
      padding-bottom: calc($rate * 40px);
      max-width: calc($rate * 1200px);
      & > div {
        padding: calc($rate * 30px) calc($rate * 20px) 8px;
      }
    }
  }
}

.burnItemBox {
  padding: 20px;
  border-radius: 16px;
  background: #202020;
  height: 100%;
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  .burnItemImg {
    width: 140px;
    flex-shrink: 0;
    margin-right: 20px;
    img {
      border-radius: 10px;
    }
  }

  .burnItemInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h3 {
      margin-bottom: 3px;
      font-size: 38px;

      @media (max-width: 1023px) {
        font-size: 36px;
      }
    }
    q {
      font-family: 'Open Sans';
      font-style: italic;
    }
    p {
      font-family: 'Open Sans';
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 20px;
      span {
        font-weight: 900;
      }
    }
  }
  @media (max-height: 960px) and (min-width: 768px) {
    .burnItemImg {
      width: 120px;
    }

    .burnItemInfo {
      h3 {
        font-size: calc($rate * 38px);
      }
      p {
        font-size: 16px;
      }
    }
  }
}
.breakLine {
  width: 70%;
  height: 5px;
  border-radius: 50%;
  background-image: linear-gradient(
    to right,
    #fffaca 20%,
    rgb(171, 249, 179) 50%,
    #fffaca 80%
  );
}
