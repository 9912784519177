@import '../../variables.scss';
.drops {
  padding-left: $sideMarginWeb;
  padding-right: $sideMarginWeb;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;

  .dropsTitle {
    font-family: 'Bebas Neue';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 4px;
    margin-bottom: $topMarginWeb;
    margin-top: 80px;
    text-align: left;
  }
  .dropItems {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 12px;
    justify-content: center;
    padding-bottom: 1px;
    overflow: auto;
    width: 100%;
  }
  @media only screen and (min-width: $tabletSize) and (max-width: $maxWidth) {
    .dropItems {
      grid-template-columns: repeat(4, 22vw);
      grid-gap: 8px;
      width: 93vw;
      justify-content: space-between;
    }
  }
  @media only screen and (max-width: $tabletSize) {
    padding: 0 20px;
    .dropItems {
      overflow-x: auto;
      overflow-y: hidden;
      margin-top: 3px;
      padding-bottom: 16px;
      margin-bottom: 40px;
      // width: 100%;
      justify-content: flex-start;
      & > a {
        width: 300px;
      }
      //   grid-template-columns: repeat(2, 45vw);
      grid-gap: 12px;
      align-self: center;
    }
  }
  //   @media (max-width: $tabletSize) {
  //     padding-left: $sideMarginMobile;
  //     padding-right: $sideMarginMobile;
  //     .dropsTitle {
  //       margin-bottom: 24px;
  //       margin-top: 40px;
  //     }
  //   }
  //   @media (max-width: $mobileSize) {
  //     .dropItems {
  //       grid-template-columns: repeat(1, 100%);
  //       grid-gap: 12px;
  //       align-self: center;
  //       width: 100%;
  //     }
  //   }
}
