@import '../../variables.scss';

$rate: 0.75;
.comingSoonPage {
  padding: 0;

  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

.comingSoonBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
  background: #1b1a1f;
  border-radius: 5px;
  margin-left: 30px;
  margin-right: 30px;
  max-width: 950px;

  @media (max-width: 425px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0;
  }

  @media (min-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1152px) {
    max-width: 1100px;
  }

  .comingSoonContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 76px 62px;

    .imgBalmainAndSpacerunners {
      margin-bottom: 51px;
      width: 406px;
      height: 45px;
    }

    @media (max-width: 767px) {
      padding: 24px 24px 28px 24px;

      .imgBalmainAndSpacerunners {
        margin-bottom: 34px;
        width: 253px;
        height: 26px;
      }
    }

    p {
      margin-bottom: 16px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-right: 60px;

      @media (max-width: 425px) {
        padding-right: 0px;
        width: auto;
        margin-bottom: 32px;
      }
    }

    .terms {
      font-size: 9.5px;
      margin-bottom: 5px;
      margin-top: 2px;
      text-transform: none;

      @media (max-width: 425px) {
        font-size: 9px;
      }
    }

    .inputError {
      color: #e54040;
      font-size: 10px;
    }

    .success {
      color: #abf9b3;
      font-size: 10px;
    }

    .waitlistInput {
      background: #2b2b2b;
      border: 1px solid #444444;
      border-radius: 6px;
      color: #ffffff;
      height: 31px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      padding: 5px 12px;
      width: 262px;

      @media (max-width: 425px) {
        width: 100%;
      }

      &:focus {
        outline: none;
      }
    }

    .waitlistInput::placeholder {
      color: #616161;
    }

    .submitRow {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    h1 {
      font-size: 33px;
      line-height: 120%;
      text-transform: uppercase;
      color: #c7c7c7;
      margin-bottom: 13px;
      font-family: 'Bebas Neue';
      font-weight: normal;

      @media (min-width: 767px) {
        font-size: 44px;
      }

      @media (max-width: 1349px) {
        max-width: 100%;
        margin-right: 0;
      }
    }

    .countdownContainer {
      .countdownTitle {
        color: #ebdb87;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
      }

      .countDownItem {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 10px 0 6px;
      }

      .digitPair {
        &:not(:first-child) {
          margin-left: 14px;
        }
      }

      .row {
        align-items: center;
        display: flex;
      }

      .digit {
        align-items: center;

        color: #ffffff;
        // border: 0.126px solid #ffffff;
        backdrop-filter: blur(8.19px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 1.26px;
        display: flex;
        font-family: 'Digital Numbers';
        font-size: 24px;
        font-weight: 400;
        height: 41px;
        justify-content: center;
        padding: 4px 3px;
        position: relative;
        width: 25px;

        &:first-child {
          margin-right: 3px;
        }

        .overlay {
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.1) 100%
          );
          bottom: 0;
          left: 0;
          opacity: 0.5;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    .countDownItemLabel {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      margin-top: 5px;
      text-align: center;
      text-transform: uppercase;
    }

    .buttonbox {
      color: #010101;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 17px;
      justify-content: center;
      padding: 10px 18px;
      width: 130px;
      height: 34px;
      background: #ffffff;
      border-radius: 7px;
    }

    .button1 {
      display: flex;
      align-items: center;
      width: fit-content;
      background-color: #ffffff;
      border-radius: 5px;
      color: #000000;
      padding: 16px 24px;
      font-family: 'Bebas Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      color: #121212;
    }

    .button2 {
      display: flex;
      align-items: center;
      padding: 16px 24px;
      border-width: 1px;
      border-radius: 5px;
      border-color: #ffffff;
      border-style: solid;
      background-repeat: no-repeat;
      background-position: center;
      height: 48px;
      width: 48px;
      cursor: pointer;
      transition: all ease 0.3s;
      &:hover {
        transform: scale(1.1);
      }
    }

    h4 {
      font-family: 'Bebas Neue';
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 8px;
      line-height: 120%;
      text-transform: uppercase;
      text-align: left;
    }

    .btns {
      display: flex;
      align-items: center;

      a {
        background: #000000;
        border-radius: 24px;
        font-size: 30px;
        line-height: 30px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 400px;
        height: 78px;
        transition: transform 0.3s ease-in-out;
        &:hover {
          transform: scale(1.06);
        }

        &.discordBtn {
          background: transparent;
          border: 2px solid #000000;
          color: black;
          width: 190px;
        }
        &.disabled {
          :hover {
            transform: none;
            cursor: not-allowed;
          }
        }
        @media (max-height: 960px) and (min-width: 768px) {
          border-radius: calc($rate * 24px);
          font-size: calc($rate * 30px);
          line-height: calc($rate * 30px);
          margin-right: calc($rate * 15px);
          width: calc($rate * 400px);
          height: calc($rate * 78px);
          &.dicordBtn {
            width: calc($rate * 190px);
          }
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
        a {
          width: 100%;
          font-size: 25px;
          margin: 0;
          &.discordBtn {
            width: 100%;
            margin-top: 15px;
          }
        }
      }
    }

    h5 {
      font-family: 'Bebas Neue';
      font-weight: normal;
      font-size: 28px;
      line-height: 100%;
      margin-bottom: 4px;
      text-align: left;
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      max-width: 700px;
      margin-right: -100px;
      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 64px;
        border-radius: 20px;
        margin: 10px 8px 10px 0;

        font-size: 30px;
        line-height: 30px;
        font-family: 'Bebas Neue';
        text-transform: uppercase;
        font-weight: normal;
        img {
          margin-right: 10px;
        }
      }
    }

    @media (max-width: 1349px) {
      h1 {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 767px) {
      h1 {
        margin-bottom: 20px;
      }

      .links {
        margin-right: 0;
        margin: -6px -5px;
        justify-content: space-around;
        p {
          width: 150px;
          height: 70px;
          margin: 6px 5px;

          font-size: 25px;

          img {
            display: none;
          }
        }
      }
    }
  }

  .comingSoonVisual {
    position: relative;
    flex: 1;
    display: flex;
    align-items: stretch;

    video {
      height: 100%;
      width: auto;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.countDown {
  display: flex;
  position: absolute;
  background: white;
  flex-direction: column;
  color: black;
  top: -27px;
  padding: 20px 30px;
  font-family: 'Bebas Neue';
  font-size: 36px;
  line-height: 100%;
  border-radius: 30px;
  text-align: left;
  font-weight: normal;

  .nextup {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #e54040;
  }
  .title {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    color: #000000;
  }
  .countDownItem {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 10px 0 6px;
  }

  @media (max-width: 1348px) {
    left: 42px;
  }
  @media (max-width: 991px) {
    font-size: 30px;
  }
  @media (max-width: 500px) {
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    .nextup {
      font-size: 13px;
    }
  }
  @media (max-height: 960px) and (min-width: 768px) {
    top: calc($rate * (-27px));
    padding: calc($rate * 20px) calc($rate * 30px);
    font-size: calc($rate * 36px);
    border-radius: calc($rate * 30px);
    .nextup {
      font-size: calc($rate * 15px);
    }
    .title {
      font-size: calc($rate * 15px);
    }
    .countDownItem {
      margin: calc($rate * 10px) 0 calc($rate * 6px);

      span {
        margin-left: calc($rate * 5px);
      }
    }
  }
}

.videoModal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    -webkit-animation: slide-in-fwd-center 1s
      cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-fwd-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    transition: all ease 0.3s;
    max-width: 80%;
    max-height: 90%;
  }
  .close {
    position: fixed;
    right: 30px;
    top: 30px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none;

    &:hover {
      background: rgba($color: #fff, $alpha: 0.2);
    }
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    z-index: -1;
    display: none;
  }
  //   &.open {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .backdrop {
    display: block;
  }
  .close {
    display: flex;
  }
  //   }
}
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    width: 0;
    height: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}
