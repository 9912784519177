@import '../../variables.scss';
$rate: 0.75;

.header {
  position: absolute;
  left: calc(50% - 50vw);
  right: calc(50% - 50vw);
  top: 0;
  display: flex;
  justify-content: center;

  & > div {
    max-width: 1440px;
  }
}
.roadmapBg {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  background: linear-gradient(
      180deg,
      transparent -8.42%,
      rgba(0, 0, 0, 0.34112) 70.87%,
      #121212 97.81%
    ),
    url(./bgRoadmap.png) top center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: -134px;
  padding-bottom: 70px;
}

.roadmapBox {
  padding: 0px $sideMarginWeb;
  overflow-x: auto;

  .title {
    font-weight: 700;
    letter-spacing: 4px;
    text-align: left;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 48px;
    margin-left: 40px;
  }
  .roads {
    display: grid;
    grid-template-columns: 38px 100px 300px 100px 300px 300px 300px;
    .road {
      display: flex;
      flex-direction: column;

      h3 {
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;

        text-transform: uppercase;

        color: #ffffff;

        opacity: 0.5;
        margin-bottom: 15px;
      }
      .line {
        position: relative;
        height: 2px;
        width: 100%;
        margin-bottom: 28px;
        svg {
          position: absolute;
          top: -6px;
          left: 0;
        }
      }
      h4 {
        font-family: 'Bebas Neue';
        font-weight: normal;
        font-size: 28px;
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        margin-top: 24px;
        max-width: 300px;
        padding: 0 15px;
      }
      p {
        margin-bottom: 12px;
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        /* or 20px */

        letter-spacing: -0.5px;
        max-width: 200px;
        &.done {
          text-decoration: line-through;
          opacity: 0.5;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .roads {
      .road {
        &:last-child {
          .line:before {
            position: absolute;
            // width: 30vw;
            top: 0;
            bottom: 0;
            background: white;
            content: '';
          }
        }
      }
    }
  }
}
.roadMobile {
  margin-top: 40px;
  background: #1b1b1b;
  padding: 28px 20px;
  .title {
    font-weight: 700;
    letter-spacing: 4px;
    text-align: left;
    margin-bottom: 28px;
  }

  & > svg {
    margin-bottom: -5px;
    display: flex;
    @media (max-width: 1020px) {
      width: 100%;
    }
  }

  .roads {
    display: grid;
    grid-template-rows: 50px 155px 50px 230px 200px 121px;
    .road {
      width: calc(100% / 6);
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;

        text-transform: uppercase;
        margin-bottom: 16px;
      }
      .line {
        position: relative;
        width: 100%;
        height: 2px;
        margin-bottom: 50px;
        svg {
          position: absolute;
          top: -19px;
          left: calc(50% - 20px);
        }
      }
      &:first-child {
        svg {
          top: -15px;
        }
        h3 {
          margin-top: -12px;
        }
      }
      &:nth-child(2) {
        h3 {
          margin-top: -5px;
        }
      }
      &:nth-child(3) {
        .line {
          top: 15%;
        }
        h3 {
          margin-top: -12px;
        }
      }
      &:nth-child(4) {
        h3 {
          margin-top: 2px;
        }
      }
      &:nth-child(5) {
        h3 {
          margin-top: -1px;
        }
      }
      &:nth-child(6) {
        h3 {
          margin-top: -7px;
        }
      }

      p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.5px;
        margin-bottom: 12px;
        &.done {
          text-decoration: line-through;
          opacity: 0.5;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .roads {
      .road {
        width: 100%;
        margin-bottom: 0;
        position: relative;
        border-left: 2px solid;
        align-items: flex-start;
        padding-left: 16px;
        .line {
          background: none;
          position: absolute;
          left: 13px;
          width: 0;
          height: 0;
          top: 9%;
          &::before {
            content: none;
          }
        }
        p,
        h4 {
          text-align: left;
          padding: 0;
          max-width: 370px;
        }
      }
    }
  }
}
