.investorItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  align-items: center;

  .logo {
    width: 144px;
    height: 144px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    &:hover img {
      transform: scale(1.05);
    }

    .link {
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      transition: all 300ms ease-in-out;
    }
  }
}
